import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../layouts/Layout';
import Seo from '../components/Seo/Seo';
import { toast } from "react-toastify";
import clsx from 'clsx';
import API from '../api';

const Imprint = () => {
  const intl = useIntl();
  const [loadingClassName, setLoadingClassName] = useState('');


  const onSubmit = (e) => {
    setLoadingClassName('is-loading');
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    API.post('/contact-us', formData)
      .then(() => {
        setLoadingClassName('');
        toast.success(
          intl.formatMessage({
            id: 'message_has_been_sent_successfully'
          }),
          {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
          }
        );
      })
      .catch(() => {
        setLoadingClassName('');
      });
    e.target.reset();
  };

  return (
    <Layout
      section={'payment'}
      bannerTitle={
        intl.formatMessage({
          id: 'footer_contact',
          defaultMessage: 'Contact',
        })
      }
    >
      <section className="section homepage-content">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="profile-content-wrapper">
                <form method="POST" name="contact" onSubmit={onSubmit}>
                  <div className="columns">
                    <div className="column is-half">
                      <div className="field">
                        <p className="control">
                          <input
                            className="input first_name"
                            name="first_name"
                            required
                            type="text"
                            placeholder={intl.formatMessage({ id: 'first_name' })}
                          />
                        </p>
                      </div>
                    </div>
                    <div className="column is-half">
                      <div className="field">
                        <p className="control">
                          <input
                            className="input last_name"
                            name="last_name"
                            required
                            type="text"
                            placeholder={intl.formatMessage({ id: 'last_name' })}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-half">
                      <div className="field">
                        <p className="control">
                          <input
                            className="input email"
                            name="email"
                            required
                            type="email"
                            placeholder={intl.formatMessage({ id: 'email' })}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <p className="control">
                          <textarea className="input message textarea" name="message" required placeholder={intl.formatMessage({ id: 'message' })} rows="8">
                          </textarea>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-half">
                      <button className={clsx("button save-changes-btn", loadingClassName)} type="submit">{intl.formatMessage({
                        id: 'send',
                        defaultMessage: 'Send',
                      })}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout >
  );
};

export default Imprint;
export const Head = ({ location, pageContext }) => (
  <Seo
    title={pageContext.intl.messages.footer_contact}
    pathname={location.pathname}
  />
);